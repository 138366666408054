<template>
  <div class="prose mb-4 mt-6 max-w-4xl dark:prose-invert">
    <h2 class="text-2xl font-semibold">What is WebSocket?</h2>
    <p>
      WebSocket is a communication protocol that enables real-time data exchange between clients and servers. Unlike traditional HTTP requests, WebSocket provides a persistent connection that allows both parties to send data at any time. This makes it ideal for applications requiring instant updates, such as chat systems and live dashboards.
    </p>

    <h3 class="mt-4 text-xl font-semibold">How WebSocket Works</h3>
    <p>
      WebSocket communication begins with a handshake that upgrades an HTTP connection to a WebSocket connection. Once established, both the client and server can send messages at any time, enabling real-time interactions.
    </p>

    <h3 class="mt-4 text-xl font-semibold">What is a WebSocket Tester?</h3>
    <p>
      A WebSocket tester is a development tool that helps you establish and test WebSocket connections. Whether you're building a chat application or implementing real-time updates, this tool helps you verify your WebSocket implementation works correctly.
    </p>

    <p>
      At this page, you can find a simple and efficient WebSocket tester that allows you to connect to any WebSocket server and exchange messages in real-time.
    </p>

    <h3 class="mt-4 text-xl font-semibold">How to Use Our WebSocket Tester</h3>
    <p>Using our WebSocket tester is simple. Follow these steps:</p>

    <h4 class="mt-2 font-semibold">Connecting to a WebSocket Server</h4>
    <ul class="list-disc pl-6">
      <li>Enter your WebSocket URL (starting with ws:// or wss://) in the input field above</li>
      <li>Click the Connect button to establish the connection</li>
      <li>Watch the connection status to confirm successful connection</li>
    </ul>

    <h4 class="mt-2 font-semibold">Sending and Receiving Messages</h4>
    <ul class="list-disc pl-6">
      <li>Type your message in the message input field</li>
      <li>Click Send to transmit your message</li>
      <li>View both sent and received messages in the message history</li>
    </ul>

    <h3 class="mt-4 text-xl font-semibold">Common Uses for WebSocket</h3>
    <div class="grid grid-cols-2 gap-4">
      <div>
        <h4 class="font-semibold">Real-time Applications</h4>
        <ul class="list-disc pl-6">
          <li>Chat Applications</li>
          <li>Live Dashboards</li>
          <li>Gaming Platforms</li>
        </ul>
      </div>
      <div>
        <h4 class="font-semibold">Data Streaming</h4>
        <ul class="list-disc pl-6">
          <li>Financial Updates</li>
          <li>IoT Monitoring</li>
          <li>Live Analytics</li>
        </ul>
      </div>
    </div>

    <div class="mt-6 text-sm text-gray-600 dark:text-gray-400">
      <p>
        Ready to start testing? Try our WebSocket tester above and experience real-time communication.
      </p>
    </div>
  </div>
</template>