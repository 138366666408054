<script setup lang="ts">
import WebsocketIntro from '~/components/app/WebsocketIntro.vue'
definePageMeta({
  layout: 'marketing',
})
useHead({
  title: 'WebSocket Tester - Online WebSocket Testing Tool',
  meta: [
    {
      name: 'description',
      content:
        'Free online WebSocket testing tool. Debug and test your WebSocket connections in real-time with our easy-to-use WebSocket debugger.',
    },
    {
      name: 'keywords',
      content:
        'websocket, websocket test, websocket tester, websocket debugger, websocket testing tool',
    },
    // Open Graph tags
    {
      property: 'og:title',
      content: 'WebSocket Tester - Online WebSocket Testing Tool',
    },
    {
      property: 'og:description',
      content:
        'Free online WebSocket testing tool. Debug and test your WebSocket connections in real-time with our easy-to-use WebSocket debugger.',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:url',
      content: 'https://websocket-tester.pages.dev',
    },
    // Twitter Card tags
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:title',
      content: 'WebSocket Tester - Online WebSocket Testing Tool',
    },
    {
      name: 'twitter:description',
      content:
        'Free online WebSocket testing tool. Debug and test your WebSocket connections in real-time.',
    },
  ],
})
</script>

<template>
  <div class="mx-auto max-w-4xl px-4 py-8">
    <WebsocketTester />
    <WebsocketIntro />
  </div>
</template>
