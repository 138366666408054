<script setup lang="ts">
import { ref, watch, nextTick, onUnmounted } from 'vue'

interface Message {
  type: 'sent' | 'received'
  content: string
  timestamp: number
}

const websocketUrl = ref('wss://echo.websocket.org')
const messageInput = ref('')
const messages = ref<Message[]>([])
const messagesContainerRef = ref<HTMLElement | null>(null)
const websocket = ref<WebSocket | null>(null)
const connectionStatus = ref<'disconnected' | 'connecting' | 'connected'>(
  'disconnected',
)

const scrollToBottom = () => {
  nextTick(() => {
    if (messagesContainerRef.value) {
      messagesContainerRef.value.scrollTop =
        messagesContainerRef.value.scrollHeight
    }
  })
}

// Watch messages for changes and scroll
watch(
  () => messages.value.length,
  () => {
    scrollToBottom()
  },
)

const connect = () => {
  if (!websocketUrl.value) return

  // Disconnect existing connection if any
  if (websocket.value) {
    websocket.value.close()
    websocket.value = null
  }

  try {
    connectionStatus.value = 'connecting'
    console.log('Attempting to connect to:', websocketUrl.value)

    websocket.value = new WebSocket(websocketUrl.value)

    websocket.value.onopen = () => {
      console.log('WebSocket connected')
      connectionStatus.value = 'connected'
      toast({
        title: 'WebSocket connected',
      })
    }

    websocket.value.onmessage = event => {
      console.log('Message received:', event.data)
      messages.value.push({
        type: 'received',
        content: event.data,
        timestamp: Date.now(),
      })
    }

    websocket.value.onclose = event => {
      console.log('WebSocket disconnected:', event.code, event.reason)
      connectionStatus.value = 'disconnected'
      toast({
        title: 'WebSocket disconnected',
      })
      websocket.value = null
    }

    websocket.value.onerror = error => {
      console.error('WebSocket error:', error)
      toast({
        title: 'Connection failed',
        description: 'Failed to connect to WebSocket server',
      })
      connectionStatus.value = 'disconnected'
      websocket.value = null
    }
  } catch (error) {
    console.error('WebSocket connection error:', error)
    toast({
      title: 'Connection failed',
      description: 'Failed to create WebSocket connection',
    })
    connectionStatus.value = 'disconnected'
    websocket.value = null
  }
}

const disconnect = () => {
  if (!websocket.value) return

  try {
    websocket.value.close(1000, 'User disconnected')
    toast({
      title: 'Disconnected',
      description: 'Disconnected from WebSocket server',
    })
  } catch (error) {
    console.error('Error closing WebSocket:', error)
    toast({
      title: 'Error',
      description: 'Error while disconnecting',
    })
  }
  websocket.value = null
  connectionStatus.value = 'disconnected'
}

const sendMessage = () => {
  if (!websocket.value || !messageInput.value) return

  try {
    websocket.value.send(messageInput.value)
    messages.value.push({
      type: 'sent',
      content: messageInput.value,
      timestamp: Date.now(),
    })
    messageInput.value = ''
  } catch (error) {
    console.error('Error sending message:', error)
    toast({
      title: 'Error',
      description: 'Failed to send message',
    })
  }
}

const clearMessages = () => {
  messages.value = []
}

const formatTime = (timestamp: number) => {
  return new Date(timestamp).toLocaleTimeString()
}

// Clean up on component unmount
onUnmounted(() => {
  if (websocket.value) {
    websocket.value.close(1000, 'Component unmounted')
    websocket.value = null
  }
})
</script>

<template>
  <div class="container mx-auto max-w-4xl space-y-6 px-4">
    <div class="space-y-2">
      <h1 class="text-center text-5xl font-bold text-primary">
        WebSocket Tester
      </h1>
      <p class="text-center text-sm text-gray-600 dark:text-gray-400">
        Test and debug your WebSocket connections online.
      </p>
    </div>

    <Card class="p-6">
      <div class="space-y-4">
        <div class="space-y-2">
          <div class="flex gap-4">
            <div class="flex-1">
              <Input
                v-model="websocketUrl"
                placeholder="ws://example.com/websocket"
                :disabled="connectionStatus !== 'disconnected'"
              />
            </div>
            <Button
              v-if="connectionStatus === 'disconnected'"
              :disabled="!websocketUrl"
              @click="connect"
            >
              Connect
            </Button>
            <Button
              v-else-if="connectionStatus === 'connecting'"
              disabled
              variant="outline"
              loading
            >
              Connecting...
            </Button>
            <Button v-else variant="destructive" @click="disconnect">
              Disconnect
            </Button>
          </div>
        </div>

        <div
          ref="messagesContainerRef"
          class="h-[400px] overflow-y-auto rounded-lg border p-4"
        >
          <div class="space-y-2">
            <template v-if="messages.length === 0">
              <div
                class="flex h-[360px] items-center justify-center text-muted-foreground"
              >
                No messages yet
              </div>
            </template>
            <div
              v-for="message in messages"
              :key="message.timestamp"
              class="flex gap-2"
              :class="message.type === 'sent' ? 'justify-end' : 'justify-start'"
            >
              <div
                class="max-w-[80%] rounded-lg p-3"
                :class="
                  message.type === 'sent'
                    ? 'bg-primary text-primary-foreground'
                    : 'bg-muted'
                "
              >
                <div class="text-sm">{{ message.content }}</div>
                <div class="mt-1 text-xs opacity-80">
                  {{ formatTime(message.timestamp) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex gap-4">
          <div class="flex-1">
            <Input
              v-model="messageInput"
              placeholder="Type a message..."
              :disabled="connectionStatus !== 'connected'"
              @keyup.enter="sendMessage"
            />
          </div>
          <Button
            @click="sendMessage"
            :disabled="connectionStatus !== 'connected' || !messageInput"
          >
            Send
          </Button>
          <Button variant="outline" @click="clearMessages"> Clear </Button>
        </div>
      </div>
    </Card>

    <div class="text-center text-sm text-muted-foreground">
      <p>
        A simple and powerful tool for testing WebSocket connections. Perfect
        for developers and testers.
      </p>
    </div>
  </div>
</template>
